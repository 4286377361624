import React from "react";
import { graphql } from "gatsby";
import * as styles from "./../CommonPostStyles.module.scss";
import { PageLayout } from "@components";
import Typography from "@components/Typography/Typography";
import { getTableOfContents, processCmsContent } from "@helpers/CmsProcessing";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";
import CmsArticleBody from "../components/CmsArticleBody";
import TableOfContents from "../components/TableOfContents";
import RelatedPosts from "../components/RelatedPosts";
import { useTrackSidebar } from "@helpers/Hooks";
import { CmsNode } from "../CmsTypes";
import CmsFeaturedImage from "../components/CmsFeaturedImage/CmsFeaturedImage";

type CMSCaseStudyTemplateProps = {
  data: {
    cmsCaseStudy: CmsNode & {
      id: string;
      field_research_focus: string;
      field_institution: string;
      field_lab_size: string;
      body: {
        processed: string;
      };
      metaTitle: string;
    };
    relatedCaseStudies: {
      nodes: CmsNode[];
    };
  };
};

export default function CMSCaseStudyTemplate({
  data, // this prop will be injected by the GraphQL query below.
}: CMSCaseStudyTemplateProps) {
  const { cmsCaseStudy, relatedCaseStudies } = data;

  const caseStudyInfo = (title: string, content: string) => (
    <div className={styles.caseStudyInfoCol}>
      <Typography
        variant="CAPTION"
        color="cloudy-blue"
        className={styles.caseStudyInfoHeader}
      >
        {title}
      </Typography>
      <Typography variant="BODY" color="ui-01">
        {content}
      </Typography>
    </div>
  );

  let article_body_elements = processCmsContent(cmsCaseStudy.body.processed);
  const TOCs = getTableOfContents(article_body_elements);
  useTrackSidebar(
    article_body_elements
      .filter((node: any) => node !== null && node.type === "h4")
      .map((toc: any) => toc.props.id),
    "ui-01",
    "cloudy-blue"
  );
  return (
    <PageLayout
      seoOptions={{
        type: "BLOG",
        data: {
          title: cmsCaseStudy.metaTitle ?? cmsCaseStudy.title,
          description: cmsCaseStudy.body.summary,
          article: true,
        }
      }}
      hideOverflowX={false}
    >
      <BackgroundFadeContainer
        type="darkBlue"
        withHeaderPadding
        className={styles.fadeContainer}
      >
        <div className={styles.templateContainer}>
          <div className={styles.header}>
            <Typography
              variant="CAPTION"
              color="cloudy-blue"
              className={styles.date}
            >
              {cmsCaseStudy.created}
            </Typography>
            <Typography
              variant="HEADER1"
              color="ui-01"
              className={styles.title}
            >
              {cmsCaseStudy.title}
            </Typography>
          </div>
          <div className={styles.postContainer}>
            <div className={styles.left}>
              <CmsFeaturedImage
                node={cmsCaseStudy}
                className={styles.featuredImage}
              />
              <div className={styles.caseStudyInfo}>
                {caseStudyInfo(
                  "Research focus",
                  cmsCaseStudy.field_research_focus
                )}
                {caseStudyInfo("Institution", cmsCaseStudy.field_institution)}
                {caseStudyInfo("Lab size", cmsCaseStudy.field_lab_size)}
              </div>
              <CmsArticleBody contents={article_body_elements} />
            </div>
            <div className={styles.right}>
              <TableOfContents contents={TOCs} />
              <RelatedPosts
                marginTop={60}
                posts={relatedCaseStudies.nodes.map((node) => {
                  return {
                    title: node.title,
                    link: node.path.alias,
                  };
                })}
              />
            </div>
          </div>
        </div>
      </BackgroundFadeContainer>
    </PageLayout>
  );
}

export const query = graphql`
  query ($CaseStudyId: String!) {
    cmsCaseStudy: nodeCaseStudy(id: { eq: $CaseStudyId }) {
      id
      title
      field_research_focus
      field_institution
      field_lab_size
      body {
        processed
        summary
      }
      created(formatString: "MMMM DD, YYYY")
      relationships {
        field_feature_image {
          relationships {
            field_media_image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          field_media_image {
            alt
          }
        }
      }
    }
    relatedCaseStudies: allNodeCaseStudy(filter: { id: { ne: $CaseStudyId } }) {
      nodes {
        id
        title
        created(formatString: "MMMM DD, YYYY")
        body {
          summary
        }
        path {
          alias
        }
        relationships {
          field_feature_image {
            relationships {
              field_media_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 808)
                  }
                }
              }
            }
            field_media_image {
              alt
            }
          }
        }
      }
    }
  }
`;
