import { ArrowRightIcons } from "@assets/icons";
import { Typography } from "@components/";
import { Link } from "@components/";
import React from "react";
import * as styles from "./index.module.scss";

export type SingleRelatedPostProps = {
    title: string;
    link: string;
}

export type RelatedPostsProps = {
    posts: SingleRelatedPostProps[];
    defaultNum?: number;
    marginTop?: number;
}

export default function RelatedPosts({
    posts,
    defaultNum = 4,
    marginTop = 0,
}: RelatedPostsProps): JSX.Element {
    return (
        <div className={styles.container} style={{ marginTop: marginTop }}>
            <Typography variant="HEADER4" color="ui-01" style={{ marginBottom: 4 }}>
                Related posts
            </Typography>
            {posts.slice(0, defaultNum).map((post) => {
                return (
                    <div className={styles.link}>
                        <div className={styles.icon}>
                            {ArrowRightIcons("ui_01")}
                        </div>
                        <Link to={post.link} internal={false}>
                            {post.title}
                        </Link>
                    </div>
                )
            })}
        </div>
    )
}